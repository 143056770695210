import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 75vw;
`;

const MopedPlate = ({ className, kenteken, ...props }) => (
    <Wrapper>
        <div
            className={ className }
            { ...props }
        >
            <div>
                { `${ kenteken.slice(0,3) }\n${ kenteken.slice(3) }` }
            </div>
        </div>
    </Wrapper>
);

export default styled(MopedPlate)`
    display:        flex;
    flex-direction: row;

    margin:        0 auto 0 0;
    
    border:        .25vw solid #111;
    border-radius: .5vw;
    overflow:      hidden;
    
    box-sizing: content-box;
    
    width:  35vw;
    height: calc(35vw / 1.16);

    & > div:last-child {
        flex: 1 1 auto;
        
        color:          inherit;
        text-transform: uppercase;
        text-shadow:    -0.0625rem -0.0625rem 0.0625rem rgba(255,255,255,.75);

        font-family:    Kenteken;
        font-size:      calc(75vw / 6.5);
        line-height:    calc(75vw / 5);
        letter-spacing: -.05em;
        text-align:     right;
        margin:         0 2.5vw -2vw 0;
        
        display:         flex;
        flex-direction:  row;
        align-items:     flex-end;
        justify-content: center;
    }

    ${ props => props.variant === 'snorfiets' ? `
        background:   #499ddd;
        color:        #FFFFFF;
        border-color: #FFFFFF;
    ` : `
        background: #f4be04;
        color:      #111111;
    ` }
`;