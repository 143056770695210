import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { Helmet } from 'react-helmet';

import formatSideCode from '../../util/formatSidecode';

import { ColorIndicator } from '../atoms';

const YearBadge = styled.span`
    font-weight: 500;

    display:       inline-block;
    background:    #CCC;
    padding:       0 .5rem;
    font-size:     1.25rem;
    line-height:   2rem;
    border-radius: .25rem;
    margin:        0 .25rem .5rem 0;
`;

const ImportBadge = styled.span`
    color:     #f44336;
    font-size: 1rem;
    margin:    0 0 0 .5rem;
`;

const BrandTypeYear = ({
    className,
    merk,
    kenteken,
    handelsbenaming,
    datum_eerste_toelating,
    datum_eerste_afgifte_nederland,
    eerste_kleur,
    tweede_kleur,
}) => {
    const fixedHandelsBenaming = (merk && handelsbenaming)
        ? handelsbenaming.replace(merk, '')
        : handelsbenaming;

    const isImport = datum_eerste_toelating && datum_eerste_afgifte_nederland && Number(datum_eerste_toelating) < Number(datum_eerste_afgifte_nederland);

    return (
        <h1
            className={ className }
        >
            <ColorIndicator
                eerste_kleur={ eerste_kleur }
                tweede_kleur={ tweede_kleur }
            />
            <div>
                { datum_eerste_toelating && (
                    <YearBadge>{ DateTime.fromISO(datum_eerste_toelating).toFormat('yyyy') }</YearBadge>
                ) }
                { merk }
                { (datum_eerste_toelating || merk) && <br/> }
                <strong>
                { (fixedHandelsBenaming || '').trim() } { isImport && <ImportBadge>IMPORT</ImportBadge> }
                </strong>
            </div>
            <Helmet>
                <title>{ formatSideCode(kenteken) }</title>
            </Helmet>
        </h1>
    );
};

BrandTypeYear.propTypes = {
    className:                      PropTypes.string,
    merk:                           PropTypes.string,
    handelsbenaming:                PropTypes.string,
    datum_eerste_toelating:         PropTypes.string,
    datum_eerste_afgifte_nederland: PropTypes.string,
    eerste_kleur:                   PropTypes.string,
    tweede_kleur:                   PropTypes.string,
};

export default styled(BrandTypeYear)`
    width:      75vw;
    margin:     0 auto 2rem;
    padding:    0 .5vw;
    box-sizing: border-box;

    font-weight:    400;
    font-size:      1.5rem;
    text-transform: uppercase;

    display: flex;
    flex-direction: row;

    & > div:last-child {
        margin: 0 0 0 2vw;
    }

    color: ${ props => props.theme.subtleTextColor };

    strong {
        font-weight: 500;
        font-size:   2rem;
        color:       ${ props => props.theme.textColor };
    }
`;