import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

const WidePlateLoader = ({ className }) => (
    <ContentLoader
        className={ className }
    />
);

export default styled(WidePlateLoader)`
    width:         75vw;
    height:        calc(75vw / 4.72 + .5vw);
    border-radius: .5vw;
`;