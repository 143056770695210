import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { WidePlate, MopedPlate, Snackbar } from '../atoms';

const LicensePlate = ({
    kenteken,
    taxi_indicator,
    datum_eerste_toelating,
    maximale_constructiesnelheid_brom_snorfiets,
}) => {
    const [ snackbar, setSnackbar ] = useState(null);

    const setClipboard = async value => {
        if (!navigator.clipboard) {
            return;
        }

        try {
            await navigator.clipboard.writeText(value);

            if (navigator.vibrate) {
                navigator.vibrate(200);
            }

            setSnackbar('Kenteken gekopieerd naar klembord');

            setTimeout(() => setSnackbar(null), 1000);
        } catch (e) {
            console.error(e);
        }
    };

    if (maximale_constructiesnelheid_brom_snorfiets) {
        return (
            <>
                <MopedPlate
                    onClick={ () => setClipboard(kenteken) }
                    kenteken={ kenteken }
                    variant={ Number(maximale_constructiesnelheid_brom_snorfiets) === 25 ? 'snorfiets' : undefined }
                />
                { snackbar && <Snackbar>{ snackbar }</Snackbar> }
            </>
        );
    }

    const taxi = taxi_indicator === 'Ja';
    const oldtimer = datum_eerste_toelating && (Number(datum_eerste_toelating) < 19780101);

    return (
        <>
            <WidePlate
                onClick={ () => setClipboard(kenteken) }
                kenteken={ kenteken }
                variant={ oldtimer ? 'oldtimer' : taxi ? 'taxi' : undefined }
            />
            { snackbar && <Snackbar>{ snackbar }</Snackbar> }
        </>
    );
};

LicensePlate.propTypes = {
    taxi_indicator:                              PropTypes.string,
    datum_eerste_toelating:                      PropTypes.string,
    maximale_constructiesnelheid_brom_snorfiets: PropTypes.string,
    kenteken:                                    PropTypes.string.isRequired,
};

export default LicensePlate;