import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

const MopedPlateLoader = ({ className }) => (
    <ContentLoader
        className={ className }
    />
);

export default styled(MopedPlateLoader)`
    margin:        0 auto 0 0;

    width:         35vw;
    height:        calc(35vw / 1.16 + .5vw);
    border-radius: .5vw;
`;