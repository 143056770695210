import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Snackbar = ({ className, children, ...rest }) => (
    <div
        className={ className }
        { ...rest }
    >
        { children }
    </div>
);

Snackbar.propTypes = {
    className: PropTypes.string,
    children:  PropTypes.node.isRequired,
};

export default styled(Snackbar)`
    position: absolute;
    left:     50%;
    top:      calc(50% - 1.5rem);
    
    transform: translate(-50%, 0);

    display:        inline-flex;
    flex-direction: row;
    align-items:    center;

    padding:       0 1rem;
    height:        3rem;

    background:    hsla(0, 0%, 0%, .87);
    border-radius: .25rem;
    color:         hsla(0, 100%, 100%, .87);
    font-weight:   400;
    font-size:     1.25rem;
`;