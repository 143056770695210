import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PirateFlag } from '../atoms';

const ErrorMessage = ({ className, children }) => (
    <div
        className={ className }
    >
        <PirateFlag />
        <div>
            <h1>Je hebt een fout gevonden! Laat dit even weten aan Freek 🙏</h1>
            Foutmelding: { children }
        </div>
    </div>
);

ErrorMessage.propTypes = {
    className: PropTypes.string,
    children:  PropTypes.node.isRequired,
};

export default styled(ErrorMessage)`
    display:        flex;
    flex-direction: row;
    align-items:    center;

    svg {
        width:  20vw;
        height: auto;
    }

    & > div {
        margin: 0 0 0 4vw;

        font-size: 1.25rem;

        h1 {
            font-weight: 500;
            font-size:   1.5rem;
        }
    }
`;