import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

const SpecsheetLoader = ({ className }) => (
    <div className={ className }>
        <ContentLoader>
            <rect x={ 0 } y={ 0 } width={ 50 } height={ 20 } />
            <rect x={ 0 } y={ 30 } width={ 120 } height={ 20 } />
        </ContentLoader>
    </div>
);

export default styled(SpecsheetLoader)`
    width:          75vw;
    margin:         4rem auto 0;
    padding:        4rem .5vw 0;
    box-sizing:     border-box;
    display:        flex;
    flex-direction: row;
    flex-wrap:      wrap;

    border-top: .125rem solid hsla(0, 0%, 0%, .08);

    height: 14.666625rem;

    svg {
        height: 100%;
    }
`;