import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ArrowBack } from '@vcnkit/core/Icons';
import { Link } from '@reach/router';
import { Helmet } from 'react-helmet';

import useRandomBRV from '../hooks/useRandomBRV';

import { Refresh, BrandTypeYearLoader, SpecsheetLoader, WidePlateLoader, MopedPlateLoader } from '../components/atoms';
import { BrandTypeYear, LicensePlate, Specsheet, ErrorMessage } from '../components/molecules';

const Wrapper = styled.div`
    margin-top: 4rem;
`;

const PlateWrapper = styled.div`
    position: relative;

    display:        flex;
    flex-direction: row;

    width: 100vw;

    & > *:only-child {
        margin: 0 auto;
    }
`;

const BigButton = styled.a`
    flex:   0 0 10vw;
    margin: 0 1.25vw;

    display:       flex;
    border-radius: .5vw;
    cursor:        pointer;

    svg {
        fill:   ${ props => props.theme.iconColor };
        margin: auto;
        width:  5vw;
        height: auto;
    }

    &:hover {
        background: ${ props => props.theme.hoverColor };
    }
`;

const Randomized = ({ where, expectsPlate = 'wide' }) => {
    const [ vehicle, { randomize, loading, error } ] = useRandomBRV(Array.isArray(where) ? [ ...where, "export_indicator = 'Nee'" ].join(' AND ') : where);

    if (loading) {
        return (
            <Wrapper>
                <BrandTypeYearLoader />
                <PlateWrapper>
                    <BigButton
                        title="Terug"
                        as={ Link }
                        to="/"
                    >
                        <ArrowBack />
                    </BigButton>
                    { expectsPlate === 'moped' ? <MopedPlateLoader /> : <WidePlateLoader /> }
                    <Helmet>
                        <title>...</title>
                    </Helmet>
                </PlateWrapper>
                <SpecsheetLoader />
            </Wrapper>
        );
    }

    if (error) {
        return (
            <Wrapper>
                <BrandTypeYearLoader />
                <PlateWrapper>
                    <BigButton
                        title="Terug"
                        as={ Link }
                        to="/"
                    >
                        <ArrowBack />
                    </BigButton>
                    <ErrorMessage>{ String(error) }</ErrorMessage>
                    <Helmet>
                        <title>Foutmelding</title>
                    </Helmet>
                </PlateWrapper>
                <SpecsheetLoader />
            </Wrapper>
        );
    }

    if (vehicle) {
        return (
            <Wrapper>
                <BrandTypeYear
                    { ...vehicle }
                />
                <PlateWrapper>
                    <BigButton
                        title="Terug"
                        as={ Link }
                        to="/"
                    >
                        <ArrowBack />
                    </BigButton>
                    <LicensePlate
                        kenteken={ vehicle.kenteken }
                        { ...vehicle }
                    />
                    <BigButton
                        onClick={ () => randomize() }
                        title="Ander kenteken, pls 🙏"
                    >
                        <Refresh />
                    </BigButton>
                </PlateWrapper>
                <Specsheet
                    { ...vehicle }
                />
            </Wrapper>
        )
    }

    return null;
};

Randomized.propTypes = {
    where: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    expectsPlate: PropTypes.oneOf([ 'wide', 'moped' ]),
};

export default Randomized;