import * as React from 'react';
import { Helmet } from 'react-helmet';

import numeral from 'numeral';
import 'numeral/locales/nl-nl';

import { Settings } from 'luxon';

import Theme from './providers/Theme';
import Router from './Router';

numeral.locale('nl-nl');
Settings.defaultLocale = 'nl';

const App = () => (
    <Theme>
        <>
            <Helmet
                titleTemplate="%s - kenteken, pls 🙏"
                defaultTitle=""
            />
            <Router />
        </>
    </Theme>
);

export default App;
