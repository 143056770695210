import React from 'react';
import { Router as ReachRouter, Redirect } from '@reach/router';
import { DateTime } from 'luxon';

import Select from './pages/Select';
import Randomized from './pages/Randomized';

const HighRiskOfTheft = [
    "handelsbenaming = 'MITSUBISHI OUTLANDER' AND cilinderinhoud = 2360 AND datum_eerste_toelating >= 20140101", // Mitsubishi Outlander PHEV
    "handelsbenaming = 'GOLF' AND cilinderinhoud = 1968 AND datum_eerste_toelating >= 20120101", // VW Golf R
    "handelsbenaming = 'S3 SPORTBACK' AND datum_eerste_toelating >= 20120101", // Audi S3 Sportback
];

const Q = {
    Personenauto: "voertuigsoort = 'Personenauto'",
    Bedrijfsauto: "voertuigsoort = 'Bedrijfsauto'",
    Motorfiets:   "voertuigsoort = 'Motorfiets'",
    Bromfiets:    "voertuigsoort = 'Bromfiets'",
    Aanhangwagen: "voertuigsoort = 'Aanhangwagen'",
    Oplegger:     "voertuigsoort = 'Oplegger'",

    // Elektrisch (BETA)
    WelElektrisch:  "cilinderinhoud IS NULL",
    NietElektrisch: "cilinderinhoud IS NOT NULL",

    // Inrichting
    Caravan:      "inrichting = 'caravan'",

    // Import
    Import:        "datum_eerste_toelating < datum_eerste_afgifte_nederland",

    // Wielen
    TweeWielen:    "aantal_wielen = 2",

    // Constr.Snelheid
    Max45:         "maximale_constructiesnelheid_brom_snorfiets = 45",
    Max25:         "maximale_constructiesnelheid_brom_snorfiets = 25",

    // Kampeerwagen
    GeenCamper:    "inrichting != 'kampeerwagen'",
    WelCamper:     "inrichting = 'kampeerwagen'",

    // Oldtimer
    GeenOldtimer: "datum_eerste_toelating >= 19780101",
    WelOldtimer:  "datum_eerste_toelating < 19780101",

    // Taxi
    GeenTaxi:     "taxi_indicator = 'Nee'",
    WelTaxi:      "taxi_indicator = 'Ja'",

    // Europese categorieen (https://www.transportpolicy.net/standard/eu-vehicle-definitions/)
    L1:        "europese_voertuigcategorie = 'L1'",
    M1:        "europese_voertuigcategorie = 'M1'",
    N1:        "europese_voertuigcategorie = 'N1'",
    N2:        "europese_voertuigcategorie = 'N2'",
    N3:        "europese_voertuigcategorie = 'N3'",
};

const Router= () => (
    <ReachRouter>
        <Select
            path="/"
        />

        {/* Personenautos */}
        <Randomized
            path="/personenauto"
            where={ [
                Q.Personenauto,
                Q.M1,
                Q.GeenTaxi,
                Q.GeenOldtimer,
                Q.GeenCamper,
            ] }
        />
        <Randomized
            path="/personenauto/nieuw"
            where={ [
                Q.Personenauto,
                Q.M1,
                Q.GeenTaxi,
                Q.GeenOldtimer,
                Q.GeenCamper,
                `datum_eerste_afgifte_nederland >= ${ DateTime.local().plus({ days: -1 }).toFormat('yyyyLLdd') }`,
            ] }
        />
        <Randomized
            path="/personenauto/vermogen"
            where={ [
                Q.Personenauto,
                Q.M1,
                Q.GeenTaxi,
                Q.GeenOldtimer,
                Q.GeenCamper,
                "vermogen_massarijklaar > 0.12",
            ] }
        />
        <Randomized
            path="/personenauto/catwaarde"
            where={ [
                Q.Personenauto,
                Q.M1,
                Q.GeenTaxi,
                Q.GeenOldtimer,
                Q.GeenCamper,
                "catalogusprijs > 80000",
            ] }
        />
        <Randomized
            path="/personenauto/diefstalgevoelig"
            where={ [
                Q.Personenauto,
                Q.M1,
                Q.GeenTaxi,
                Q.GeenOldtimer,
                Q.GeenCamper,
                `(${ HighRiskOfTheft.map(v => `(${ v })`).join(' OR ') })`,
            ] }
        />
        <Randomized
            path="/personenauto/import"
            where={ [
                Q.Personenauto,
                Q.M1,
                Q.GeenTaxi,
                Q.GeenOldtimer,
                Q.GeenCamper,
                Q.Import
            ] }
        />
        <Randomized
            path="/personenauto/oldtimer"
            where={ [
                Q.Personenauto,
                Q.M1,
                Q.GeenTaxi,
                Q.WelOldtimer,
            ] }
        />
        <Randomized
            path="/personenauto/kampeerwagen"
            where={ [
                Q.Personenauto,
                Q.M1,
                Q.GeenTaxi,
                Q.GeenOldtimer,
                Q.WelCamper,                
            ] }
        />
        <Randomized
            path="/personenauto/taxi"
            where={ [
                Q.Personenauto,
                Q.M1,
                Q.GeenOldtimer,
                Q.GeenCamper,
                Q.WelTaxi,
            ] }
        />

        {/* Bedrijfswagens */}
        <Randomized
            path="/bedrijfswagen"
            where={ [
                Q.Bedrijfsauto,
                Q.GeenOldtimer,
                Q.GeenTaxi,               
            ] }
        />
        <Randomized
            path="/bedrijfswagen/licht"
            where={ [
                Q.Bedrijfsauto,
                Q.GeenOldtimer,
                Q.GeenTaxi,
                Q.N1,
            ] }
        />
        <Randomized
            path="/bedrijfswagen/middelzwaar"
            where={ [
                Q.Bedrijfsauto,
                Q.GeenOldtimer,
                Q.GeenTaxi,
                Q.N2,
            ] }
        />
        <Randomized
            path="/bedrijfswagen/zwaar"
            where={ [
                Q.Bedrijfsauto,
                Q.GeenOldtimer,
                Q.GeenTaxi,
                Q.N3,
            ] }
        />

        {/* Brom- of snorfiets */}
        <Randomized
            path="/bromsnorfiets"
            expectsPlate="moped"
            where={ [
               Q.Bromfiets,
               Q.GeenOldtimer, 
               Q.TweeWielen,
            ] }
        />
        <Randomized
            path="/bromsnorfiets/bromfiets"
            expectsPlate="moped"
            where={ [
               Q.Bromfiets,
               Q.GeenOldtimer, 
               Q.TweeWielen,
               Q.Max45,
            ] }
        />
        <Randomized
            path="/bromsnorfiets/snorfiets"
            expectsPlate="moped"
            where={ [
               Q.Bromfiets,
               Q.GeenOldtimer, 
               Q.TweeWielen,
               Q.Max25,
            ] }
        />
        <Randomized
            path="/bromsnorfiets/oldtimer"
            expectsPlate="moped"
            where={ [
               Q.Bromfiets,
               Q.WelOldtimer, 
               Q.TweeWielen,
            ] }
        />
        <Randomized
            path="/bromsnorfiets/speed-pedelec"
            expectsPlate="moped"
            where={ [
                Q.Bromfiets,
                Q.L1,
                Q.GeenOldtimer,
                Q.TweeWielen,
                Q.WelElektrisch,
                "massa_ledig_voertuig < 30",
            ] }
        />

        {/* Motor */}
        <Randomized
            path="/motor"
            where={ [
                Q.Motorfiets,
                Q.GeenOldtimer,
                Q.TweeWielen,
            ] }
        />
        <Randomized
            path="/motor/import"
            where={ [
                Q.Motorfiets,
                Q.TweeWielen,
                Q.Import,
            ] }
        />
        <Randomized
            path="/motor/oldtimer"
            where={ [ 
                Q.Motorfiets,
                Q.WelOldtimer,
            ] }
        />

        {/* Aanhangwagen */}
        <Randomized
            path="/aanhangwagen"
            where={ [
                Q.Aanhangwagen,
            ] }
        />
        <Randomized
            path="/aanhangwagen/caravan"
            where={ [
                Q.Aanhangwagen,
                Q.Caravan,
            ] }
        />
        <Randomized
            path="/aanhangwagen/oplegger"
            where={ [
                Q.Oplegger,
            ] }
        />

        <Redirect
            from="/*"
            to="/"
            default
            noThrow
        />
    </ReachRouter>
);

export default Router;