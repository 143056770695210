import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Block } from '../atoms';

function getHexByColor (color) {
    switch (color) {
        case 'BEIGE':
            return '#E1C699';
        case 'BLAUW':
            return '#1e88e5';
        case 'BRUIN':
            return '#6d4c41';
        case 'CREME':
            return '#fffdd0';
        case 'GEEL':
            return '#fdd835';
        case 'GRIJS':
            return '#C0C0C0';
        case 'GROEN':
            return '#66bb6a';
        case 'ORANJE':
            return '#ff9800';
        case 'PAARS':
            return '#ab47bc';
        case 'ROOD':
            return '#ef5350';
        case 'ROSE':
            return '#f06292';
        case 'WIT':
            return '#FFFFFF';
        case 'ZWART':
            return '#000000';
        default:
            return null;
    }
}

const ColorIndicator = ({ className, eerste_kleur, tweede_kleur }) => {
    const primaryHex = getHexByColor(eerste_kleur);
    const secondaryHex = getHexByColor(tweede_kleur);

    return (
        <div
            className={ className }
            style={ (primaryHex || secondaryHex) ?  { backgroundColor: primaryHex || secondaryHex } : undefined }
            title={ (primaryHex && secondaryHex) ? `${ eerste_kleur }, ${ tweede_kleur }` : primaryHex ? eerste_kleur : 'Kleur is onbekend' }
        >
            { (secondaryHex && primaryHex) && (
                <div
                    style={ {
                        backgroundColor: secondaryHex,
                    } }
                />
            ) }
            { (!primaryHex && !secondaryHex) && <Block /> }
        </div>
    );
};

ColorIndicator.propTypes = {
    className:    PropTypes.string,
    eerste_kleur: PropTypes.string,
    tweede_kleur: PropTypes.string,
};

export default styled(ColorIndicator)`
    height: 4.791875rem;
    width:  4.791875rem;

    max-width: 4.791875rem;

    border:        .125rem solid hsla(0, 0%, 0%, .18);
    border-radius: .25rem;

    box-sizing: border-box;

    position: relative;
    overflow: hidden;

    display:        flex;
    flex-direction: row;

    svg {
        height: 50%;
        width:  auto;
        margin: auto;
        fill:   hsla(0, 0%, 0%, .18);
    }

    & > div {
        position: absolute;
        transform: rotate(45deg);

        top:  0;
        left: 20%;

        width:  100%;
        height: 200%;
    }
`;