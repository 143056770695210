import { useEffect, useState, useRef } from 'react';
import axios from 'axios';

/**
 * Get a random offset
 *
 * @param {*} max 
 * @param {*} usedNumbers 
 */
function getRandomOffset(max, usedNumbers = []) {
    if (usedNumbers.length + 1 >= max) {
        throw 'Exhausted result-set';
    }

    const num = Math.floor(Math.random() * max);
    if (usedNumbers.includes(num)) {
        return getRandomOffset(max, usedNumbers);
    }

    return num;
}

export default function useRandomBrv(where) {
    const [ vehicle, setVehicle ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(null);
    const totalResults = useRef(0);
    const usedOffsets = useRef([]);

    const reset = () => {
        setVehicle([]);
        setError(null);
        totalResults.current = 0;
        usedOffsets.current = [];
    };

    useEffect(
        () => {
            reset();

            const getCount = async () => {
                setLoading(true);

                try {
                    const result = (await axios.get(`https://opendata.rdw.nl/resource/m9d7-ebf2.json?$select=count(kenteken)&$where=${ where }`)).data;
                
                    if (result.length > 0) {
                        totalResults.current = Number(result[0].count_kenteken);
                        await randomize();
                    }
                } catch (e) {
                    console.error(e);

                    setError(e);
                } finally {
                    setLoading(false);
                }
            };

            getCount();
        },
        [ where ],
    );

    const randomize = async () => {
        setLoading(true);

        try {
            const offset = getRandomOffset(totalResults.current, usedOffsets.current);
            usedOffsets.current.push(offset);

            const vehicle = (await axios.get(`https://opendata.rdw.nl/resource/m9d7-ebf2.json?$where=${ where }&$limit=1&$offset=${ offset }`)).data[0];
            setVehicle(vehicle);
        } catch (e) {
            console.error(e);

            setError(e);
        } finally {
            setLoading(false);
        }
    };

    return [ vehicle, { randomize, loading, error }];
}