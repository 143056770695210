import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { Helmet } from 'react-helmet';

import { ArrowForward } from '../components/atoms';

const Wrapper = styled.div`
    margin-top: 4rem;
    width:      75vw;

    display:        flex;
    flex-direction: row;
    flex-wrap:      wrap;

    & > * {
        flex: 0 0 calc(100% - 2rem);

        @media only screen and (min-width: 64em) {
            flex: 0 0 calc(50% - 2em);
        }

        @media only screen and (min-width: 80em) {
            flex: 0 0 calc(33.33333% - 2rem);
        }

        @media only screen and (min-width: 105em) {
            flex: 0 0 calc(25% - 2rem);
        }
    }
`;

const Category = styled.div`
    display:        flex;
    flex-direction: column;

    font-size: 1rem;
    margin:    0 0 2rem 2rem;

    & > a {
        text-decoration: none;
        display:         flex;
        flex-direction:  row;
        align-items:     center;
        padding:         0 1rem;
        border-radius:   .25rem;
        height:          3rem;

        ${ props => `
            color: ${ props.theme.subtleTextColor };
            fill:  ${ props.theme.iconColor };

            &:first-child {
                color: ${ props.theme.textColor };
                fill:  ${ props.theme.iconColor };
            }

            &:hover {
                background: ${ props.theme.hoverColor };
            }
        ` }

        svg {
            height: 1rem;
            margin: 0 0 0 auto;
        }

        &:first-child {
            font-size: 1.25rem;
        }

        span {
            font-size:   .75rem;
            color:       hsla(0, 0%, 0%, .37);
            font-weight: 500;
            margin:      0 0 0 .5rem;
        }
    }
`;

const Select = () => (
    <Wrapper>
        <Category>
            <Link to="/personenauto">Personenauto<ArrowForward /></Link>
            <Link to="/personenauto/nieuw">Sinds gisteren op kenteken<ArrowForward/></Link>
            <Link to="/personenauto/vermogen">Hoog relatief vermogen<ArrowForward /></Link>
            <Link to="/personenauto/catwaarde">Hoge cataloguswaarde<ArrowForward /></Link>
            <Link to="/personenauto/diefstalgevoelig">Diefstalgevoelig<ArrowForward /></Link>
            <Link to="/personenauto/import">Geimporteerd<ArrowForward /></Link>
            <Link to="/personenauto/oldtimer">Oldtimer<ArrowForward /></Link>
            <Link to="/personenauto/kampeerwagen">Kampeerwagen<ArrowForward /></Link>
            <Link to="/personenauto/taxi">Taxi<ArrowForward /></Link>
        </Category>
        <Category>
            <Link to="/bedrijfswagen">Bedrijfswagen<ArrowForward /></Link>
            <Link to="/bedrijfswagen/licht">Licht<ArrowForward /></Link>
            <Link to="/bedrijfswagen/middelzwaar">Middelzwaar<ArrowForward /></Link>
            <Link to="/bedrijfswagen/zwaar">Zwaar<ArrowForward /></Link>
        </Category>
        <Category>
            <Link to="/bromsnorfiets">Brom- of snorfiets<ArrowForward /></Link>
            <Link to="/bromsnorfiets/bromfiets">Bromfiets<ArrowForward /></Link>
            <Link to="/bromsnorfiets/snorfiets">Snorfiets<ArrowForward /></Link>
            <Link to="/bromsnorfiets/speed-pedelec">Speed-Pedelec <span>BETA</span><ArrowForward /></Link>
            <Link to="/bromsnorfiets/oldtimer">Oldtimer<ArrowForward /></Link>
        </Category>
        <Category>
            <Link to="/motor">Motor<ArrowForward /></Link>
            <Link to="/motor/import">Geimporteerd<ArrowForward /></Link>
            <Link to="/motor/oldtimer">Oldtimer<ArrowForward /></Link>
        </Category>
        <Category>
            <Link to="/aanhangwagen">Aanhangwagen<ArrowForward /></Link>
            <Link to="/aanhangwagen/caravan">Caravan<ArrowForward /></Link>
            <Link to="/aanhangwagen/oplegger">Oplegger<ArrowForward /></Link>
        </Category>
        <Helmet>
            <title>Selecteer een categorie</title>
        </Helmet>
    </Wrapper>
)

export default Select;