import * as React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

const NormalizeStyles = createGlobalStyle`
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}
button,
input { /* 1 */
  overflow: visible;
}
button,
select { /* 1 */
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
`;

const BaseStyles = createGlobalStyle`
    body {
        background:  #FFFFFF;
        background: linear-gradient(to bottom, #FFFFFF, #FAFAFA);
    
        min-height:      100vh;
        display:         flex;
        flex-direction:  column;
        align-items:     center;
        justify-content: flex-start;
    
        ${ props => `
            font-family: ${ props.theme.fontFamily };
            color:       ${ props.theme.textColor };
        ` }
    }
`;

const theme = {
    fontFamily: '"Roboto", sans-serif',

    hoverColor: 'hsla(0, 0%, 0%, .08)',
    focusColor: 'hsla(0, 0%, 0%, .08)',

    textColor:       'hsla(0, 0%, 0%, .87)',
    subtleTextColor: 'hsla(0, 0%, 0%, .67)',

    iconColor: 'hsla(0, 0%, 0%, .57)',

    invertedTextColor:       '#FFFFFF',
    invertedSubtleTextColor: 'hsla(255, 100%, 100%, .87)',

    invertedIconColor: '#FFFFFF',

    inactiveScrollColor: 'hsla(0, 0%, 0%, .12)',
    activeScrollColor:   'hsla(0, 0%, 0%, .36)',
};

const Theme = ({ children }) => (
    <>
        <NormalizeStyles />
        <ThemeProvider theme={ theme }>
            <>
                <BaseStyles />
                { children }
            </>
        </ThemeProvider>
    </>
);

Theme.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Theme;