const sideCodeRegex = [
    /^[a-zA-Z]{2}[\d]{2}[\d]{2}$/,      // Sidecode  1: XX-99-99
    /^[\d]{2}[\d]{2}[a-zA-Z]{2}$/,      // Sidecode  2: 99-99-XX
    /^[\d]{2}[a-zA-Z]{2}[\d]{2}$/,      // Sidecode  3: 99-XX-99
    /^[a-zA-Z]{2}[\d]{2}[a-zA-Z]{2}$/,  // Sidecode  4: XX-99-XX
    /^[a-zA-Z]{2}[a-zA-Z]{2}[\d]{2}$/,  // Sidecode  5: XX-XX-99
    /^[\d]{2}[a-zA-Z]{2}[a-zA-Z]{2}$/,  // Sidecode  6: 99-XX-XX
    /^[\d]{2}[a-zA-Z]{3}[\d]{1}$/,      // Sidecode  7: 99-XXX-9
    /^[\d]{1}[a-zA-Z]{3}[\d]{2}$/,      // Sidecode  8: 9-XXX-99
    /^[a-zA-Z]{2}[\d]{3}[a-zA-Z]{1}$/,  // Sidecode  9: XX-999-X
    /^[a-zA-Z]{1}[\d]{3}[a-zA-Z]{2}$/,  // Sidecode 10: X-999-XX
    /^[a-zA-Z]{3}[\d]{2}[a-zA-Z]{1}$/,  // Sidecode 11: XXX-99-X
    /^[a-zA-Z]{1}[\d]{2}[a-zA-Z]{3}$/,  // Sidecode 12: X-99-XXX
    /^[\d]{1}[a-zA-Z]{2}[\d]{3}$/,      // Sidecode 13: 9-XX-999
    /^[\d]{3}[a-zA-Z]{2}[\d]{1}$/       // Sidecode 14: 999-XX-9
];

/**
 * Convert value with a sidecode index to the proper format.
 *
 * @param {string} value
 * @param {int} sideCode
 *
 * @returns {string}
 */
const convert = (value, sideCode) => {
    switch (sideCode) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
            return value.substr(0, 2) + '-' + value.substr(2, 2) + '-' + value.substr(4, 2);
        case 7:
        case 9:
            return value.substr(0, 2) + '-' + value.substr(2, 3) + '-' + value.substr(5, 1);
        case 8:
        case 10:
            return value.substr(0, 1) + '-' + value.substr(1, 3) + '-' + value.substr(4, 2);
        case 11:
        case 14:
            return value.substr(0, 3) + '-' + value.substr(3, 2) + '-' + value.substr(5, 1);
        case 12:
        case 13:
            return value.substr(0, 1) + '-' + value.substr(1, 2) + '-' + value.substr(3, 3);
    }
};

/**
 * Adds dashes to the correct positions for a dutch license plate.
 * Source: http://blog.kenteken.tv/2011/05/06/code-snippets-formatteren-rdw-kenteken/
 *
 * @param {string} value
 *
 * @returns {string}
 */
export default function format (value) {
    let val = value;

    if (typeof value !== 'undefined') {
        val = value.replace(/\-/g, '').toUpperCase();
        for (let i = 0; i < sideCodeRegex.length; i++) {
            if (sideCodeRegex[i].test(val)) {
                return convert(val, i + 1);
            }
        }
    }

    return value;
}