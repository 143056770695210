import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

const BrandTypeYearLoader = ({ className }) => (
    <div className={ className }>
        <ContentLoader>
            <rect x={ 0 } y={ 0 } height={ 130 } width={ 130 } />
            <rect x={ 170 } y={ 0 } width={ 300 } height={ 50 } />
            <rect x={ 170 } y={ 70 } width={ 800 } height={ 60 } />
        </ContentLoader>
    </div>
);

export default styled(BrandTypeYearLoader)`
    width:      75vw;
    margin:     0 auto 2rem;
    padding:    0 .5vw;
    box-sizing: border-box;

    height: 4.791875rem;

    svg {
        height: 100%;
    }
`;