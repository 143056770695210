import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import numeral from 'numeral';

const StringValue = ({ label, value }) => value ? (
    <div>
        <h6>{ label }</h6>
        { value }
    </div>
) : null;

const DateValue = ({ label, value }) => value ? (
    <div>
        <h6>{ label }</h6>
        { DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT) }
    </div>
) : null;

const NumberValue = ({ label, value, format = '0,0', suffix = '' }) => value ? (
    <div>
        <h6>{ label }</h6>
        { `${ numeral(Number(value)).format(format) } ${ suffix }`.trim() }
    </div>
) : null;

const CurrencyValue = ({ label, value }) => value ? (
    <div>
        <h6>{ label }</h6>
        { numeral(Number(value)).format('$0,0') }
    </div>
) : null;

const Specsheet = ({
    className,
    catalogusprijs,
    datum_eerste_toelating,
    datum_eerste_afgifte_nederland,
    datum_tenaamstelling,
    massa_ledig_voertuig,
    maximum_massa_samenstelling,
    vermogen_massarijklaar,
    cilinderinhoud,
    aantal_cilinders,
    aantal_wielen,
    aantal_deuren,
    aantal_zitplaatsen,
    zuinigheidslabel,
    europese_voertuigcategorie,
    wam_verzekerd,
}) => (
    <div className={ className }>
        <CurrencyValue
            label="Catalogusprijs"
            value={ catalogusprijs }
        />
        <NumberValue
            label="Massa"
            value={ massa_ledig_voertuig }
            suffix="kg"
        />
        <NumberValue
            label="Max. massa"
            value={ maximum_massa_samenstelling }
            suffix="kg"
        />
        <DateValue
            label="Datum eerste toelating"
            value={ datum_eerste_toelating }
        />
        <DateValue
            label="Datum eerste afgifte"
            value={ datum_eerste_afgifte_nederland }
        />
        <DateValue
            label="Datum tenaamstelling"
            value={ datum_tenaamstelling }
        />
        <NumberValue
            label="Relatief vermogen"
            value={ vermogen_massarijklaar }
            format='0.00'
        />
        <NumberValue
            label="Cilinders"
            value={ aantal_cilinders }
        />
        <NumberValue
            label="Cilinderinhoud"
            value={ cilinderinhoud }
            suffix="cc"
        />
        <NumberValue
            label="Wielen"
            value={ aantal_wielen }
        />
        <NumberValue
            label="Deuren"
            value={ aantal_deuren }
        />
        <NumberValue
            label="Zitplaatsen"
            value={ aantal_zitplaatsen }
        />
        <StringValue
            label="Zuinigheidslabel"
            value={ zuinigheidslabel }
        />
        <StringValue
            label="Europese voertuigcat."
            value={ europese_voertuigcategorie }
        />
        <StringValue
            label="WAM Verzekerd"
            value={ wam_verzekerd }
        />
    </div>
);

Specsheet.propTypes = {
    className:                      PropTypes.string,
    catalogusprijs:                 PropTypes.string,
    datum_eerste_toelating:         PropTypes.string,
    datum_eerste_afgifte_nederland: PropTypes.string,
    datum_tenaamstelling:           PropTypes.string,
    massa_ledig_voertuig:           PropTypes.string,
    maximum_massa_samenstelling:    PropTypes.string,
    vermogen_massarijklaar:         PropTypes.string,
    cilinderinhoud:                 PropTypes.string,
    aantal_cilinders:               PropTypes.string,
    aantal_wielen:                  PropTypes.string,
    aantal_deuren:                  PropTypes.string,
    aantal_zitplaatsen:             PropTypes.string,
    europese_voertuigcategorie:     PropTypes.string,
    zuinigheidslabel:               PropTypes.string,
    wam_verzekerd:                  PropTypes.string,
};

export default styled(Specsheet)`
    width:          75vw;
    margin:         4rem auto 0;
    padding:        4rem .5vw 0;
    box-sizing:     border-box;
    display:        flex;
    flex-direction: row;
    flex-wrap:      wrap;

    border-top: .125rem solid hsla(0, 0%, 0%, .08);

    & > * {
        flex: 0 0 calc(100% - 2rem);

        @media only screen and (min-width: 64em) {
            flex: 0 0 calc(50% - 2em);
        }

        @media only screen and (min-width: 80em) {
            flex: 0 0 calc(33.33333% - 2rem);
        }

        @media only screen and (min-width: 105em) {
            flex: 0 0 calc(25% - 2rem);
        }

        font-size: 1.125rem;
        color:     ${ props => props.theme.subtleTextColor };
        margin:    0 0 2rem;

        h6 {
            color:       ${ props => props.theme.textColor };
            font-weight: 500;
            font-size:   1.125rem;
            margin:      0 0 .75rem 0;
        }
    }
`;